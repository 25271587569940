import TitledImageLink from '../molecules/TitledImageLink'
import { HomePage_JumbotronInfoServices } from '../content/types.d'

type Props = {
  title: string
  content: string
  services: HomePage_JumbotronInfoServices[]
}

const JumboInfo = ({ title, content, services }: Props) => {
  return (
    <section className="w-full mx-auto max-w-container grid grid-cols-1 xl:grid-cols-sm-left">
      <div className="px-4 md:px-8 text-white bg-blue xl:px-jumbotron-left divide-y-2 divide-white divide-opacity-30 row-start-2 xl:row-start-1">
        <div className="flex flex-col justify-center h-32">
          <h1 className="text-lg font-medium">{title}</h1>
        </div>
        <div className="pt-8 pb-12 text-sm leading-loose ">{content}</div>
      </div>
      <div className="grid grid-cols-2 grid-rows-link-boxes">
        {services.map((service: HomePage_JumbotronInfoServices) => (
          <TitledImageLink
            key={service.text}
            title={service.text}
            href={service.href}
          >
            <div className="w-full h-full bg-teal">
              <div
                className="bg-center aspect-[4/3] lg:h-full lg:aspect-auto"
                style={{
                  backgroundImage: `url(${service.image})`,
                  backgroundSize: 'cover',
                }}
              />
            </div>
          </TitledImageLink>
        ))}
      </div>
    </section>
  )
}

export default JumboInfo
