import Link from '@/atoms/Link'

type Props = {
  title: string
  href: string
  children: React.ReactNode
}

const TitledImageLink = ({ title, href, children }: Props) => {
  return (
    <>
      {title && title.trim() !== '' && (
        <div className="flex flex-col justify-center h-32 row-start-1 row-span-1 col-span-2">
          <Link href={href}>{title}</Link>
        </div>
      )}
      <div className="row-start-2 row-span-1">{children}</div>
    </>
  )
}

export default TitledImageLink
