import classNames from 'classnames'

type Props = {
  title: string
  className?: string

  // either content + links or only content and no links
  children: [React.ReactNode, React.ReactNode] | [React.ReactNode] // impossible to assign a specific type to the second child
}

const MiniArticleBox = ({ title, className, children }: Props) => {
  const [content, links] =
    children.length === 2 ? children : [...children, <></>]

  return (
    <div className={`text-sm leading-8 ${className ?? ''}`}>
      <h1 className="text-3xl xl:text-6xl leading-snug xl:leading-snug mb-8">
        {title}
      </h1>
      <div className={classNames({ 'mb-12': children.length === 2 })}>
        {content}
      </div>
      {children.length === 2 && links}
    </div>
  )
}

export default MiniArticleBox
