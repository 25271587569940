import Head from 'next/head'
import HeaderBar from '../organisms/HeaderBar'
import Jumbotron from '../organisms/Jumbotron'
import JumboInfo from '../organisms/JumboInfo'
import SeparatorHeader from '../atoms/SeparatorHeader'
import LeftCol from '../atoms/LeftCol'
import RightCol from '../atoms/RightCol'
import MiniArticleBox from '../organisms/MiniArticleBox'
import UnderlinedLink from '../atoms/UnderlinedLink'
import SpacedRow from '../atoms/SpacedRow'
import Cases from '../molecules/Cases'
import Footer from '../organisms/Footer'
import Container from '../molecules/Container'
import ThemeBox from '../atoms/ThemeBox'
import FadeWrapper from '@/molecules/FadeWrapper'

// TODO: maybe auto generate this from the cms config, rather than using
// a script to generate this manually (see: package.json -> cmstypes)
import { HomePage, HomePage_Services } from '../content/types'
import { attributes as contents } from '../content/home.md'
import { useLocale } from '../hooks/useLocale'
import AdaptiveThemeBox from '../atoms/AdaptiveThemeBox'
import MetaInformation from '../utils/MetaInformation'
import Link from '@/atoms/Link'
import WayOfWorking from '@/organisms/WayOfWorking'
import is from '@sindresorhus/is'
import PartnersLogos from '@/organisms/PartnersLogos'

const Home = () => {
  const { translations, locale } = useLocale<HomePage>(contents)

  const makeServiceRow = (service: HomePage_Services, i: number) => {
    const particles = <img src={service.image} alt={service.image_alt} />
    const content = (
      <MiniArticleBox title={service.service_title}>
        <p>{service.service_body}</p>
        <Link href={service.href} variant="secondary">
          {service.link_text}
        </Link>
      </MiniArticleBox>
    )

    if (is.evenInteger(i)) {
      return (
        <SpacedRow key={i}>
          <LeftCol>{particles}</LeftCol>
          <RightCol>{content}</RightCol>
        </SpacedRow>
      )
    } else {
      return (
        <SpacedRow key={i}>
          <LeftCol below>{content}</LeftCol>
          <RightCol above>{particles}</RightCol>
        </SpacedRow>
      )
    }
  }

  return (
    <>
      <Head>
        <title>{translations.page_title}</title>
        <MetaInformation locale={locale} {...translations.meta} />
      </Head>
      <AdaptiveThemeBox defaultTheme="black">
        <HeaderBar />
      </AdaptiveThemeBox>
      <ThemeBox theme="black">
        <div className="text-black bg-white black:bg-black black:text-white light:bg-gray-700 lg:pt-4">
          <div className="flex items-center md:pb-8">
            <Jumbotron
              title={translations.jumbo_title}
              preTitle={translations.jumbo_pre_title}
              img={translations.jumbo_img}
            />
          </div>
          <JumboInfo
            title={translations.jumbo_info_header}
            content={translations.jumbo_info_content}
            services={translations.jumbo_info_services}
          />
          <SeparatorHeader
            title={translations.services_title}
            subtitle={translations.services_subtitle}
            extraClasses="mt-12 xl:mt-48 "
          />
          <Container>
            {translations.services_list.map(makeServiceRow)}
          </Container>
          <div className="pb-16 text-center xl:pb-36">
            <UnderlinedLink
              className="text-sm"
              title={translations.services_readmore}
              href="/services"
            />
          </div>
        </div>
      </ThemeBox>
      <FadeWrapper>
        <ThemeBox theme="bright">
          <section className="text-black bg-white black:bg-black black:text-white light:bg-gray-700">
            <FadeWrapper>
              <SeparatorHeader
                title={translations.cases_title}
                subtitle={translations.cases_subtitle}
                extraClasses="py-16 lg:pb-20 lg:pt-36"
              />
              <Cases cases={translations.cases} locale={locale} />
              <div className="mt-20 text-center pb-36">
                <UnderlinedLink
                  className="text-sm"
                  title={translations.cases_readmore}
                  href="/projects"
                />
              </div>
            </FadeWrapper>
          </section>
        </ThemeBox>
      </FadeWrapper>
      <ThemeBox theme="bright">
        <section className="py-16 text-black bg-white xl:py-36 black:bg-black black:text-white light:bg-gray-700">
          <SeparatorHeader
            title={translations.insight_through_data_title}
            subtitle={translations.insight_through_data_subtitle}
          />
          <Container>
            <WayOfWorking />
            <div className="mt-12 text-center xl:mt-20">
              <UnderlinedLink
                className="text-sm"
                title={translations.about_us_readmore}
                href="/about"
              />
            </div>
          </Container>
        </section>
      </ThemeBox>
      <ThemeBox theme="light">
        <section className="py-16 text-black bg-white xl:py-36 black:bg-black black:text-white light:bg-gray-700">
          <PartnersLogos />
        </section>
      </ThemeBox>
      <ThemeBox theme="black">
        <Footer />
      </ThemeBox>
    </>
  )
}

export default Home
