import TimelineStep from '@/atoms/TimelineStep'
import classNames from 'classnames'
import {
  ComponentProps,
  RefObject,
  createRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { minBy } from 'lodash'

type TimelineStep = Omit<
  ComponentProps<typeof TimelineStep>,
  'active' | 'activated'
>

type TimelineProps = {
  steps: TimelineStep[]
  progress: number
}

const HEADERHEIGHT = 96

const Timeline = ({ steps }: TimelineProps) => {
  // TODO: For now, this component only works when we have exactly 5 steps. To
  // make the number of steps dynamic, we need to figure how to disable the
  // translate transforms for mobile layouts and then implement the translations
  // with inline style.
  // Perhaps one thing we can investigate is creating a custom class, which has
  // an important! modifier. Alternatively, maybe we can explicitly disable the
  // translation applied through inline-style via a tailwind class that has an
  // important modifier: lg:!translate-none.
  const [progress, setProgress] = useState(0)
  const [isDesktop, setIsDesktop] = useState<boolean>()

  const translateClasses = [
    'lg:translate-x-[calc(-000%-5rem*0)]',
    'lg:translate-x-[calc(-100%-5rem*1)]',
    'lg:translate-x-[calc(-200%-5rem*2)]',
    'lg:translate-x-[calc(-300%-5rem*3)]',
    'lg:translate-x-[calc(-400%-5rem*4)]',
  ]

  const progressClasses = [
    'w-1 h-[0%] lg:h-1 lg:w-[0%]',
    'w-1 h-[25%] lg:h-1 lg:w-[25%]',
    'w-1 h-[50%] lg:h-1 lg:w-[50%]',
    'w-1 h-[75%] lg:h-1 lg:w-[75%]',
    'w-1 h-[100%] lg:h-1 lg:w-[100%]',
  ]

  const makeTextStyle = (idx: number): string => {
    return classNames('lg:text-justify leading-relaxed transition-all', {
      // desktop
      'lg:scale-[60%] lg:cursor-pointer lg:text-black/20 lg:select-none lg:text-lg':
        progress != idx,
    })
  }

  const stepsRefs = useRef<RefObject<HTMLDivElement>[]>([])
  useMemo(() => {
    stepsRefs.current = steps.map(
      (_ref, i) => (stepsRefs.current[i] = createRef<HTMLDivElement>()),
    )
  }, [steps])

  const observer = useRef<IntersectionObserver>()
  useEffect(() => {
    // Create an observer that updates the progress state based on the step that
    // is closest to the top of the screen. This is most useful when the screen
    // is tall enough to display more than 1 step at a time (e.g. iPads).
    const distanceToTop = (ref: RefObject<HTMLDivElement>) => {
      return Math.abs(ref.current.getBoundingClientRect().top - HEADERHEIGHT)
    }
    const setProgressToClosest = (entries: IntersectionObserverEntry[]) => {
      if (entries.some((e) => e.isIntersecting)) {
        const closestToTop: RefObject<HTMLDivElement> = minBy(
          stepsRefs.current,
          distanceToTop,
        )
        setProgress(Number.parseInt(closestToTop?.current.dataset.nth, 10) ?? 0)
      }
    }
    observer.current = new IntersectionObserver(setProgressToClosest, {
      threshold: [0, 0.5, 1],
    })
  }, [])

  useEffect(() => {
    // Connect the observer to the steps on smaller screens and disconnect on
    // larger screens.
    if (!observer.current) return
    if (!isDesktop) {
      stepsRefs.current.forEach((ref) => {
        observer.current.observe(ref.current)
      })
    } else {
      observer.current.disconnect()
    }

    return () => {
      observer.current.disconnect()
    }
  }, [observer, stepsRefs, isDesktop])

  useEffect(() => {
    const setScreenSize = () => {
      const matches = window.matchMedia('(min-width: 1024px)').matches // equivalent to 'lg' breakpoint
      setIsDesktop(matches)
    }
    window.addEventListener('resize', setScreenSize)
    setScreenSize()
    return () => {
      window.removeEventListener('resize', setScreenSize)
    }
  }, [])

  const onClick = (i: number) => {
    // Scroll to the step on smaller screens or setProgress on larger screens
    if (!isDesktop) {
      window.scrollTo({
        top:
          stepsRefs.current[i].current.getBoundingClientRect().top +
          window.scrollY -
          (HEADERHEIGHT + 16),
        behavior: 'smooth',
      })
    } else {
      setProgress(i)
    }
  }

  return (
    <div className="relative">
      <div className="sticky lg:static top-1/3 lg:translate-y-0 h-[350px] lg:mt-0 lg:h-auto lg:w-full">
        <div className="lg:relative absolute -left-6 lg:left-0 lg:mx-20 mx-3 scale-75 lg:scale-100">
          <div className="absolute h-[350px] w-1 lg:h-1 lg:w-full flex lg:flex-col flex-row left-3 lg:left-0 lg:bottom-3 py-3.5 lg:py-0 lg:px-3.5">
            <div className="inline-block black:bg-white h-full lg:h-px w-px lg:w-full bg-black/40 transition-all" />
            <div
              className={`${progressClasses[progress]} bg-current transition-all`}
            />
          </div>
          <div className="z-10 flex flex-col lg:flex-row lg:h-auto h-[350px] justify-between">
            {steps.map((s, i) => (
              <div
                key={i}
                className="cursor-pointer"
                onClick={() => onClick(i)}
              >
                <TimelineStep
                  key={s.title}
                  activated={progress > i}
                  active={progress == i}
                  {...s}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="ml-8 -mt-[350px] lg:mt-0 lg:ml-0 flex justify-center md:overflow-x-clip">
        <div className="lg:mt-20 flex flex-col lg:flex-row max-w-prose lg:mx-20 gap-20">
          {steps.map((s, i) => (
            <div
              key={i}
              ref={stepsRefs.current[i]}
              data-nth={i.toString()}
              className={`w-full lg:flex-base-100 lg:flex-grow-0 lg:flex-shrink-0 transition-all ${translateClasses[progress]}`}
            >
              <div className="text-2xl lg:hidden mb-4">{s.title}</div>
              <div onClick={() => setProgress(i)} className={makeTextStyle(i)}>
                {s.description}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Timeline
