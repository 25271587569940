import Timeline from '@/molecules/Timeline'

import { attributes as contents } from '../content/way_of_working.md'
import { useLocale } from '@/hooks/useLocale'
import { WayOfWorking } from '@/content/types'

const WayOfWorking = () => {
  const { translations } = useLocale<WayOfWorking>(contents)

  return (
    <div className="container mx-auto">
      <div className="pt-20">
        <Timeline progress={1} steps={translations.steps} />
      </div>
    </div>
  )
}

export default WayOfWorking
